export default [
  { model: 'default', title: "Default" },
  { model: 'champion', title: "Champion Nível 1" },
  // {model:'champion_2', title: "Champion Nível 2"},
  { model: 'materials', title: "Materiales" },
  { model: 'training_programa_level_1', title: "Training Program Level 1" },
  { model: 'training_programa_level_2', title: "Training Program Level 2" },
  { model: 'onboarding_chile', title: "Onboarding Fluke - Chile" },
  { model: 'exp_program_champion_2023', title: "Excperimentação Programa Champion 2023" },
];
