<template>
  <div>
    <v-select
      v-model="selected"
      :label="$tt.capitalize($t('labels.teams'))"
      :items="courseTeams"
      @change="value => $emit('input', value)"
      multiple
      chips
      outlined
      clearable
    />
  </div>
</template>

<script lang="js">
import * as teamService from '../../services/teams'

export default {
  props: {
    value: {
      type: Array,
    },
  },
	data: () => ({
    readonly: false,
    teams: [],
    selected: [],
    loaded: false
  }),
  computed: {
    courseTeams() {
      return this.teams.map(team => {
        return {
          text: team.name,
          value: team._id
        }
      });
    }
  },
  watch: {
    value: function(value) {
      this.applyData(value);
    },
  },
  created() {
    this.prepareData()
  },
  methods: {
    async prepareData() {
      this.teams = await teamService.list()
      this.applyData(this.value)
    },
    applyData(value){
      if(!this.selected.length && !this.loaded){
        this.loaded = true
        this.selected = value.map(item=>(item._id))
      }
    }
  }
}
</script>
