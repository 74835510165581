<template>
  <div>
    <f-master-select v-model="course.masters" :readonly="readonly" />

    <f-team-select v-model="course.teams" />

    <f-training-category-select v-model="course.trainingCategory" />
  </div>
</template>

<script lang="js">
import FMasterSelect from '../shared/MasterSelect.vue'
import FTeamSelect from '../shared/TeamSelect.vue'
import FTrainingCategorySelect from '../shared/TrainingCategorySelect.vue'

export default {
  components: {
    FMasterSelect,
    FTeamSelect,
    FTrainingCategorySelect
  },
  data: () => ({
    readonly: false,
  }),
  computed: {
		course(){
			return this.$store.state.course.current
		},
  },
}
</script>
