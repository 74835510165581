<template>
  <div>
    <!-- LANGUAGES SELECTOR -->
    <v-row>
      <v-col cols="12" sm="12">
        <v-select
          v-model="course.langs"
          outlined
          :items="$store.state.system.langs"
          :label="$tt.capitalize($t('labels.languages'))"
          multiple
          :rules="rules"
        >
          <template v-slot:prepend-item>
            <v-list-item ripple @click="langsSelectedToggle">
              <v-list-item-action>
                <v-icon>
                  {{ langsSelectedIconCtl }}
                </v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  {{ $tt.capitalize($t('labels.selectAll')) }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider class="mt-2"></v-divider>
          </template>

          <template v-slot:selection="{ item, index }">
            <span v-if="index === 0">
              <span>{{ $tt.capitalize($t('labels.' + item)) }}</span>
            </span>
            <span v-if="index === 1" class="grey--text caption select_aux">
              (+{{ course.langs.length - 1 }} {{ $t('labels.others') }})
            </span>
          </template>

          <template v-slot:item="{ item, attrs, on }">
            <v-list-item v-on="on" v-bind="attrs" #default="{ active }">
              <v-list-item-action>
                <v-checkbox :input-value="active"></v-checkbox>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  <span>{{ $tt.capitalize($t('labels.' + item)) }} </span>
                  <v-spacer></v-spacer>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-select>
      </v-col>
    </v-row>

    <!-- TITLE -->
    <v-row v-if="course.langs.length">
      <v-col cols="12" sm="12">
        <f-lang-selector :langs="course.langs" />
        <v-tabs-items v-model="selectedLang">
          <v-tab-item v-for="lang in course.langs" :key="lang">
            <div class="input-spacer">
              <v-text-field
                v-model="course.title[lang]"
                :rules="[requireAllLang('title')]"
                outlined
                :label="
                  $tt.capitalize($t('labels.title')) +
                    $tt.uppercase(` (${lang})`)
                "
                :counter="maxTitleSize"
              />
            </div>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>

    <!-- DESCRIPTION -->
    <v-row v-if="course.langs.length">
      <v-col cols="12" sm="12">
        <f-lang-selector :langs="course.langs" />
        <v-tabs-items v-model="selectedLang">
          <v-tab-item v-for="lang in course.langs" :key="lang">
            <div class="input-spacer">
              <v-textarea
                v-model="course.description[lang]"
                :rules="[requireAllLang('description')]"
                outlined
                :label="
                  $tt.capitalize($t('labels.description')) +
                    $tt.uppercase(` (${lang})`)
                "
              />
            </div>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import validatorRules from '../../helpers/validator_rules';

export default {
  data: () => ({
    currentImage: '',
    tab: 0,
    maxTitleSize: 100,
  }),
  computed: {
    selectedLang: {
      get() {
        return this.$store.state.system.selectedLang;
      },
      set(value) {
        this.$store.state.system.selectedLang = value;
      },
    },
    course() {
      return this.$store.state.course.current;
    },
    allLangs() {
      return this.course.langs.length === this.$store.state.system.langs.length;
    },
    someLangs() {
      return this.course.langs.length > 0 && !this.allLangs;
    },
    langsSelectedIconCtl() {
      if (this.allLangs) return 'mdi-close-box';
      if (this.someLangs) return 'mdi-minus-box';
      return 'mdi-checkbox-blank-outline';
    },
    minLengthRule() {
      return [
        value => (value && value.length > 0) || this.$t('rules.required'),
      ];
    },
    rules() {
      return this.minLengthRule;
    },
    requiredRule() {
      return validatorRules.required(this.$t('rules.required'));
    },
  },
  methods: {
    langsSelectedToggle() {
      this.$nextTick(() => {
        if (this.allLangs) {
          this.course.langs = [];
        } else {
          this.course.langs = [...this.$store.state.system.langs];
        }
      });
    },
    requireAllLang(value) {
      let response = false;
      if (validatorRules.checkLangData(this.course[value], this.course.langs)) {
        response = true;
      } else {
        response = this.$t('rules.allLangsField');
      }
      return response;
    },
  },
};
</script>
