<template>
  <div>
    <f-group-container>
      <div>{{ $tt.capitalize($t('labels.structure')) }}:</div>
      <br />
      <v-radio-group
        v-model="course.structure"
        mandatory
        inline
        row
        :disabled="this.$store.getters['system/isEditPage']"
      >
        <v-radio label="Linear" value="linear" />
        <v-radio label="Modular" value="modular" />
      </v-radio-group>
    </f-group-container>

    <f-group-container>
      <v-switch
        v-model="course.generateCertificate"
        :label="$t('labels.generateCertificateAtTheEnd')"
      />

      <v-select
        :disabled="!course.generateCertificate"
        v-model="course.certificate_layout"
        :items="certificateLayouts"
        item-value="model"
        item-text="title"
        :label="$tt.capitalize($t('labels.certificateLayout'))"
        outlined
      />
    </f-group-container>

    <v-switch
      v-model="course.require_completed_modules_before_exam"
      :label="requireLabel"
    />

    <v-switch v-model="status" :label="statusLabel" />
  </div>
</template>

<script>
import certificateLayouts from '../../data/certificateLayouts';
import { KEYS as COURSE_STATUS } from '../../data/courseStatuses';
import FGroupContainer from '../shared/GroupContainer.vue';

export default {
  components: {
    FGroupContainer,
  },
  data: () => ({
    radioGroup: 'linear',
    certificateLayouts,
    status: false,
  }),
  computed: {
    course() {
      return this.$store.state.course.current;
    },
    requireLabel() {
      let label;
      if (this.course.structure == 'linear') {
        label = 'Require completed previous lessons before exam';
      } else {
        label = 'Require completed current module before exam';
      }

      return label;
    },
    statusLabel() {
      return this.status
        ? this.$i18n.t('labels.status.published')
        : this.$i18n.t('labels.status.draft');
    },
  },
  created() {
    if (
      this.$store.getters['system/isEditPage'] &&
      this.course.status == COURSE_STATUS.PUBLISHED
    )
      this.status = true;
  },
  watch: {
    status: function(value) {
      this.course.status = value
        ? COURSE_STATUS.PUBLISHED
        : COURSE_STATUS.DRAFT;
    },
  },
};
</script>
