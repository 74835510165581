<template>
  <div>
    <v-select
      v-model="selected"
      :items="items"
      :label="$tt.capitalize($t('labels.trainingCategory'))"
      @change="value => $emit('input', value)"
      outlined
      multiple
      chips
      clearable
    />
  </div>
</template>

<script lang="js">
import trainingCategories from '../../data/trainingCategories';

export default {
  props: {
    value: {
      type: Array,
    },
  },
  data: () => ({
    readonly: false,
    items: trainingCategories,
    selected: [],
    loaded: false
  }),
  watch: {
    value: function(value) {
      this.prepareData(value);
    },
  },
  created() {
    this.prepareData(this.value);
  },
  methods: {
    prepareData(value) {
      if(!this.selected.length && !this.loaded){
        this.loaded = true;
        this.selected = value;
      }
    }
  }
}
</script>
