import store from '../store';
import apiUtils from '../helpers/api_utils';

const ROUTE = 'team';

export const list = async (filters = null) => {
  const TOKEN = store.getters['auth/userToken'];

  let query = '';
  if (filters) query = '?' + apiUtils.jsonToQuery(filters);

  return (await apiUtils.get(`${ROUTE}/list${query}`, {}, TOKEN)).body;
};

export const get = async id => {
  const TOKEN = store.getters['auth/userToken'];

  return (await apiUtils.get(`${ROUTE}/get/${id}`, {}, TOKEN)).body;
};

export const save = async item => {
  const TOKEN = store.getters['auth/userToken'];

  return (await apiUtils.post(`${ROUTE}/save`, item, TOKEN)).body;
};
