<template>
  <div>
    <label class="image-holder">
      <img
        v-if="currentImage"
        :src="currentImage"
        class="image-holder__image"
      />
      <v-icon v-if="!currentImage" dark class="image-holder__icon">
        mdi-image
      </v-icon>
      <input
        class="image-holder__input"
        type="file"
        @change.prevent="onChangeImage"
      />
    </label>
    <p><em>Best image size: 500x200</em></p>
  </div>
</template>

<script lang="js">
import * as courseService from '../../services/courses'
import fileUtils from '../../helpers/file_utils'

export default {
	data: () => ({
		previewImage: null
	}),
	computed: {
		course(){
			return this.$store.state.course.current
		},
		currentImage() {
      const imageURL = this.course.image
        ? courseService.getImageURL(this.course.image)
        : null;
      return this.previewImage || imageURL;
    },
	},
	methods: {
		async onChangeImage(e){
      try {
        const file = e.target.files[0];
        this.course.image = file;
        this.previewImage = await fileUtils.fileToBase64(file);
      } catch(e) {}
    }
	}
}
</script>

<style lang="scss">
.image-holder {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(148, 148, 148);
  width: 500px;
  height: 200px;
  position: relative;
  cursor: pointer;
  overflow: hidden;
  &__image {
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;
  }
  &__input {
    position: absolute;
    opacity: 0;
    top: 0;
    left: 0;
    width: 0;
    height: 0;
  }
}
</style>
