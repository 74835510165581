<template>
  <div>
    <lms-default-loader v-if="isLoading" />

    <div v-if="!isLoading">
      <f-page-header :title="title" :prevLinks="prevLinks" />

      <f-container topBottom>
        <f-form />
      </f-container>
    </div>
  </div>
</template>

<script>
import FForm from '../../components/Courses/CreateEditForm.vue';
import { KEYS as COURSE_STATUS } from '../../data/courseStatuses';

export default {
  components: {
    FForm,
  },
  computed: {
    prevLinks() {
      return [
        {
          title: this.$t('labels.courses'),
          route: {
            name: 'course-list',
          },
        },
      ];
    },
    course() {
      return this.$store.getters['course/current'];
    },
    courseCurrentLanguage() {
      return this.$store.getters['course/courseCurrentLanguage'];
    },
    title() {
      let title = this.$t('labels.createCourse');

      if (this.$store.getters['system/isEditPage']) {
        title = this.course.title[this.courseCurrentLanguage];
      }

      return title;
    },
  },
  created() {
    this.prepareData();
  },
  methods: {
    async prepareData() {
      if (this.$store.getters['system/isEditPage']) {
        await this.$store.dispatch('course/get', this.$route.params.id);
      } else {
        this.$store.state.course.current = {
          title: {},
          description: {},
          langs: [],
          masters: [],
          teams: [],
          require_completed_modules_before_exam: false,
          status: COURSE_STATUS.DRAFT,
        };
      }

      this.$store.commit('system/pageLoaded');
    },
  },
  beforeDestroy() {
    this.$store.commit('course/clearCurrent');
  },
};
</script>
<!-- teste github actions -->