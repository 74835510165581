<template>
  <div>
    <v-card elevation="0">
      <v-card-text>
        <v-form ref="form">
          <f-image />
          <f-general />
          <f-groups />
          <f-others />
        </v-form>
      </v-card-text>
    </v-card>

    <lms-btn-holder>
      <v-btn
        color="primary"
        large
        elevation="0"
        @click="save"
        :disabled="false"
        :loading="false"
      >
        {{ $t('labels.save') }}
      </v-btn>

      <v-btn large depressed @click="back">
        {{ $t('labels.cancel') }}
      </v-btn>
    </lms-btn-holder>
  </div>
</template>

<script lang="js">
import FImage from './CEFImage.vue'
import FGeneral from './CEFGeneral.vue'
import FGroups from './CEFGroups.vue'
import FOthers from './CEFOthers.vue'

export default {
	components: {
		FImage,
		FGeneral,
		FGroups,
		FOthers
	},
	methods: {
		async save(){
      const valid = this.$refs.form.validate();
      const action = this.$route.name != 'course-edit' ? 'update' : 'create';

      if(valid) {
        await this.$store.dispatch('course/save')

        if (this.$route.name != 'course-edit') {
          this.back();
        } else {
          this.$router.push({
            name: 'course-view',
            params: {
              id: this.$route.params.id
            }
          })
        }

        this.$gtm.trackEvent({
          category: 'course-admin-actions',
          event: 'click',
          action: 'course-click',
          label: `${action}-course`,
          value: this.$route.params.id ?`edit-${this.$route.params.id}` : 'new',
        });
      }
		},
    back() {
      this.$router.push({
        name: 'course-list'
      });
    }
	}
}
</script>
