export const KEYS = {
  CHAMPION: 'Champion',
  SELLER: 'Seller',
  SELLER_BR: 'Seller BR',
  SELLER_LAAM: 'Seller LAAM',
  MARKETING: 'Marketing',
  CUSTOMER: 'Customer',
  FLUKE_STAFF: 'Fluke Staff',
  FLUKE_MKT: 'Fluke MKT',
  FLUKE_KAM: 'Fluke KAM',
  FLUKE_TSM: 'Fluke TSM',
  FACT: 'FACT',
};

export default Object.values(KEYS);
