import { VChip } from 'vuetify/lib/components/VChip';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VSelect } from 'vuetify/lib/components/VSelect';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c(VSelect,_vm._b({ref:"masterSelect",staticClass:"masterSelect",style:({
      opacity: _vm.readonly ? 0.5 : 1,
      pointerEvents: _vm.readonly ? 'none' : 'initial',
    }),attrs:{"prepend-inner-icon":_vm.icon ? _vm.icon : '',"items":_vm.items,"label":!_vm.placeholder ? _vm.label : '',"placeholder":_vm.placeholder ? _vm.label : '',"multiple":_vm.isMultiple,"chips":_vm.isMultiple,"rules":_vm.rules,"readonly":_vm.readonly,"clearable":!_vm.readonly,"menu-props":{ contentClass: 'masterSelect-content' },"elevation":"0"},on:{"click:clear":function($event){_vm.selected = []},"change":value => _vm.$emit('input', value)},scopedSlots:_vm._u([(_vm.isMultiple)?{key:"prepend-item",fn:function(){return [_c('div',{staticClass:"masterSelect-selectAll"},[_c(VListItem,{attrs:{"ripple":""},on:{"click":_vm.selectAllMasters}},[_c(VListItemAction,[_c(VIcon,[_vm._v(" "+_vm._s(_vm.selectMasterIcon)+" ")])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v(" "+_vm._s(_vm.$tt.capitalize(_vm.$t('labels.selectAll')))+" ")])],1)],1),_c(VDivider,{staticClass:"mt-2"})],1)]},proxy:true}:null,{key:"item",fn:function(data){return [_c(VChip,[_vm._v(_vm._s(data.item.text))])]}},{key:"selection",fn:function({ item , index }){return [(!_vm.innerMultipleLines)?_c('div',[(_vm.isMultiple)?_c(VChip,[_c('div',{staticClass:"masterSelect-chip"},[_c('div',[_vm._v(_vm._s(item.text))]),_c('div',{staticClass:"masterSelect-chip--subtitle"},[_vm._v(_vm._s(item.country))])])]):_c('span',[_vm._v(_vm._s(item.text)+" ("+_vm._s(item.country)+")")])],1):_vm._e(),(_vm.innerMultipleLines && _vm.keepHeight)?_c('div',[(index === 0)?_c(VChip,[_c('div',{staticClass:"masterSelect-chip"},[_c('div',[_vm._v(_vm._s(item.text))]),_c('div',{staticClass:"masterSelect-chip--subtitle"},[_vm._v(_vm._s(item.country))])])]):_vm._e(),(index === 1)?_c('span',[_vm._v(" +"+_vm._s(_vm.selected.length)+" ")]):_vm._e()],1):_vm._e()]}}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}},'v-select',_vm.selectAttrsComp,false))],1)
}
var staticRenderFns = []

export { render, staticRenderFns }