<template>
  <div>
    <v-select
      ref="masterSelect"
      class="masterSelect"
      v-model="selected"
      :prepend-inner-icon="icon ? icon : ''"
      :items="items"
      :label="!placeholder ? label : ''"
      :placeholder="placeholder ? label : ''"
      :multiple="isMultiple"
      :chips="isMultiple"
      :rules="rules"
      :readonly="readonly"
      :clearable="!readonly"
      :style="{
        opacity: readonly ? 0.5 : 1,
        pointerEvents: readonly ? 'none' : 'initial',
      }"
      @click:clear="selected = []"
      :menu-props="{ contentClass: 'masterSelect-content' }"
      @change="value => $emit('input', value)"
      v-bind="selectAttrsComp"
      elevation="0"
    >
      <!-- list header slot -->
      <template #prepend-item v-if="isMultiple">
        <div class="masterSelect-selectAll">
          <v-list-item ripple @click="selectAllMasters">
            <v-list-item-action>
              <v-icon>
                {{ selectMasterIcon }}
              </v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                {{ $tt.capitalize($t('labels.selectAll')) }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider class="mt-2"></v-divider>
        </div>
      </template>

      <template #item="data">
        <v-chip>{{ data.item.text }}</v-chip>
      </template>

      <!-- chips slot -->
      <template #selection="{ item , index }">
        <div v-if="!innerMultipleLines">
          <v-chip v-if="isMultiple">
            <div class="masterSelect-chip">
              <div>{{ item.text }}</div>
              <div class="masterSelect-chip--subtitle">{{ item.country }}</div>
            </div>
          </v-chip>
          <span v-else>{{ item.text }} ({{ item.country }})</span>
        </div>
        <div v-if="innerMultipleLines && keepHeight">
          <v-chip v-if="index === 0">
            <div class="masterSelect-chip">
              <div>{{ item.text }}</div>
              <div class="masterSelect-chip--subtitle">{{ item.country }}</div>
            </div>
          </v-chip>
          <span v-if="index === 1"> +{{ selected.length }} </span>
        </div>
      </template>
    </v-select>
  </div>
</template>

<script>
import * as countryService from '../../services/countries';

export default {
  props: {
    value: {
      type: [Array, String],
    },
    isMultiple: {
      type: Boolean,
      default: true,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    optional: {
      type: Boolean,
      default: false,
    },
    keepHeight: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: Boolean,
      default: false,
    },
    noCounter: {
      type: Boolean,
      default: false,
    },
    selectAttrs: {
      type: Object,
    },
    icon: {
      type: [Boolean, String],
      default: false,
    },
  },
  data: () => ({
    selected: [],
    initialFilled: false,
    countries: [],
    loaded: false,
    innerMultipleLines: false,
  }),
  computed: {
    items() {
      if (this.countries.length) {
        const locale = this.$i18n.locale;
        let output = [],
          countryName;
        this.countries.forEach(country => {
          countryName = country.name[locale];
          output.push({
            header: countryName,
          });

          country.masters.forEach(master => {
            output.push({
              value: master._id,
              text: master.title,
              country: countryName,
            });
          });

          output.push({
            divider: true,
          });
        });
        return output;
      } else {
        return [];
      }
    },
    rules() {
      if (!this.optional) {
        return this.minLengthRule;
      }
      return [];
    },
    minLengthRule() {
      return [
        value => (value && value.length > 0) || this.$t('rules.required'),
      ];
    },
    selectMasterIcon() {
      if (this.selectedAllMasters) return 'mdi-close-box';
      if (this.selectedSomeMaster) return 'mdi-minus-box';
      return 'mdi-checkbox-blank-outline';
    },
    selectedAllMasters() {
      const totalMasters = this.countries.reduce((acc, item) => {
        return acc + item.masters.length;
      }, 0);
      return totalMasters === this.selected.length;
    },
    selectedSomeMaster() {
      return this.selected.length > 0;
    },
    label() {
      if (this.isMultiple) {
        return this.$tt.capitalize(this.$t('labels.masters'));
      }
      return this.$tt.capitalize(this.$t('labels.master'));
    },
    selectAttrsComp() {
      const defaultAttrs = {
        outlined: true,
        counter: !this.noCounter,
      };
      return {
        ...defaultAttrs,
        ...this.selectAttrs,
      };
    },
  },
  created() {
    this.get();
  },
  methods: {
    async get() {
      const result = await countryService.list({
        status: 'enabled',
      });
      this.countries = result.items;
      if (this.value) {
        this.preSelect();
      }
    },
    preSelect() {
      if (this.$props.value) {
        if (this.isMultiple) {
          this.$props.value.forEach(item => {
            if (typeof item == 'string') {
              this.selected.push(item);
            } else {
              this.selected.push(item._id);
            }
          });
          // this.selected = this.$props.value;
        } else {
          if (typeof this.$props.value[0] != 'undefined') {
            this.selected = this.$props.value[0]._id;
          }
        }
      }
    },
    selectAllMasters() {
      if (this.selectedAllMasters) {
        this.selected = [];
      } else {
        this.selected = this.countries
          .map(country => {
            return country.masters
              .map(master => {
                return master._id;
              })
              .flat();
          })
          .flat();
      }
      this.$emit('input', this.selected);
    },
  },
  watch: {
    selected: function(value) {
      // fix clear bug
      if (!value) {
        this.selected = [];
        setTimeout(() => {
          document.querySelector('.v-application').click();
        }, 500);
      }

      if (this.keepHeight) {
        this.innerMultipleLines = false;
        setTimeout(() => {
          const elemHeight = this.$refs.masterSelect.$el.getBoundingClientRect()
            .height;
          if (elemHeight > 106) {
            this.innerMultipleLines = true;
          }
        }, 50);
      }
    },
    value: function() {
      if (!this.selected.length && !this.loaded) {
        this.loaded = true;
        this.preSelect();
      }
    },
  },
};
</script>
